import React, { Component } from 'react';
import storage from '../../storage/index'
import APIAuth from '../../API/APIAuth';
import APIEmpresas from "../../API/APIEmpresas";
import { withRouter } from 'react-router-dom'
import aux from "../../utils/auxiliar"
import SVG from "./security.svg"
import SilverInput from "../../components/silverinput"
import Loading from '../../components/loading/Loading';
import Alert from '../../components/alert/Alert';
import ModalResetPassword from './modalresetpassword';
import { ShowMessageContext } from "../../contexts/showmessage"

import {
  Container,
  SvgContainer,
  LoginContainer,
  ButtonAcess,
  BackButton,
  PasswordContainer
} from "./style"
import Checkbox from '../../components/checkbox';



class Login extends Component {

  static contextType = ShowMessageContext;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    pass: '',
    loading: false, show: false,
    alertMessage: "",
    step: 1, displayEmp: 'none',
    findPerson: false,
    cpf: '',
    empresaSelect: { key: 0, texto: "" },
    codigoEmpresa: "",
    empresaI: false,
    passI: false,
    CPFI: false,
    codigoEI: false,
    closeShowMessage: null,
    confirmNewPassword: "",
    newPassword: "",
    empresas: [],
    imageReady: false,
    splash: true,
    svgW: 0,
    svgH: 0
  };

  componentDidMount() {
    aux.answerAPP();
    storage.cleanALL();
    if (this.ref.current) {
      let h = this.ref.current.clientHeight;
      let w = this.ref.current.clientWidth;
      this.setState({ svgW: w - 300 });
    }
  }

  timesLoading = 0;

  loadBackGround = () => {
    this.timesLoading++;
    if (this.timesLoading === 2) this.setState({ imageReady: true }, () => {
      setTimeout(() => { this.setState({ splash: false }) }, 2000);
    })
  }

  showMessage = (mensagem, error) => {
    this.context.show(mensagem, error)
  }


  goToChangePassword = () => {
    this.avancar(3);
  }

  cadastraNovaSenha = async () => {
    this.setState({ loading: true });
    if (this.isPasswordsValid()) {
      const response = await APIAuth.cadNewPassword(this.state.confirmNewPassword, this.state.newPassword, this.state.cpf, this.state.empresaSelect);

      if (response === 200) {
        this.setState({
          newPassword: "",
          confirmNewPassword: "",
        });
        this.props.history.push('/home');
        this.showMessage("Sua senha foi alterada. Por favor, faça o login com sua nova senha");
      } else if (response === 401) {
        // Tratar Erro
        console.log(response);
      } else if (response === 403) {
        console.log(response);
      } else if (response === 17) {
        this.showMessage("Nova senha inferior a 8 dígitos");
      }
    }
    this.setState({ loading: false });
  }



  closeMessage = () => {
    this.setState({ show: false, alertMessage: "" }, () => {
      if (this.state.closeShowMessage) {
        this.state.closeShowMessage();
      }
    });
  }


  isPasswordsValid = () => {

    if (!this.state.newPassword || !this.state.confirmNewPassword) {
      this.showMessage("Ambos os campos são obrigatórios");
      return false;
    }

    if (this.state.confirmNewPassword !== this.state.newPassword) {
      this.showMessage("Senhas não Conferem")
      return false;
    }

    if (this.state.newPassword.length < 8) {
      this.showMessage("A senha deve ter no mínimo 8 caracteres")
      return false;
    }

    return true;

  }

  handleNewPassword = (e) => {
    this.setState({ newPassword: e.target.value });
  }

  handleConfirmPassword = (e) => {
    this.setState({ confirmNewPassword: e.target.value });
  }


  handleUser = (e) => {
    this.setState({ cpf: aux.cpfMask(e.target.value), CPFI: false });
  }

  handlePass = (e) => {
    this.setState({ pass: e.target.value });
  }

  handleCodigoE = (e) => {
    this.setState({ codigoEmpresa: e.target.value, codigoEI: false });
  }

  handleEmpresas = (e) => {
    const selected = parseInt(e.target.value);
    debugger
    const { key, url, codigo, value, cnpj, text } = this.state.empresas.filter(o => o.key === selected)[0];
    this.setState({ empresaSelect: { key: key, texto: text, url: url, codigo: codigo, value, cnpj }, empresaI: false });
    storage.setUrlClient(url);
  }

  showLoading = (show) => {
    this.setState({ loading: show });
  }

  validaLogin = async () => {

    try {
      this.showLoading(true);
      const { cpf, pass } = this.state;
      debugger
      if (cpf && pass) {

        const response = await APIAuth.validaLogin(cpf, pass, this.state.empresaSelect);
        if (response === 200) {

          APIAuth.setLogAcesso({ cpf: cpf, codempresa: this.state.empresaSelect.codigo, pass: pass });

          storage.setCodEmpresa(this.state.empresaSelect.codigo);


          this.showLoading(false);
          this.props.history.push('/home');

        } else if (response === 401) { // usuario nao autorizado

          this.showLoading(false);
          this.showMessage('Acesso Negado', true)

        } else if (response === 500) { // erro no servidor

          this.showLoading(false);
          this.showMessage('Ops.. algo deu errado. Vamos tentar novamente.', true);

        } else if (response === 403) {//senha expirada

          this.showLoading(false);
          this.showMessage("Sua senha está expirada. Use a funcionalidade de trocar a senha.", true);
        }
      } else {
        this.showLoading(false);
        this.showMessage('Acesso Negado', true);
      }
    } catch (error) {
      console.log(error);
      this.showMessage('Ops... algo deu errado. Vamos tentar novamente', true);
      this.showLoading(false);
    }
  }


  cadastraPerfil = () => {
    this.isCamposPerfilValido();
  }


  isCamposPerfilValido = () => {
    const CPFI = !this.validaCpf();
    const codigoEI = this.state.codigoEmpresa === "";
    this.setState({ CPFI, codigoEI });

    if (CPFI || codigoEI) {
      this.showMessage("Campos obrigatórios inválidos", true);
      return false;
    }

    return true;
  }

  validaCpf = () => {
    // Posteriormente vamo validar se o cpf eh valido.         
    return this.state.cpf !== "";
  }

  isCamposValidos = () => {
    const empresaI = this.state.empresaSelect.key === 0;
    const passI = this.state.pass === "";

    this.setState({ empresaI, passI });
    if (empresaI || passI) {
      this.showMessage("Campos obrigatórios não preenchidos", true);
      return false;
    }

    return true;
  }

  avancar = (step) => {

    if (step !== 3) {
      if (this.state.step === 2) return;
      this.setState({ step: this.state.step + 1 });
    } else {

      this.setState({ step });
    }

  }

  voltar = () => {
    if (this.state.step !== 3) {
      if (this.state.step === 1) return;
      this.setState({ step: this.state.step - 1 });
    } else {

      this.setState({ step: 1 });
    }

  }

  acessaEmpresas = async () => {
    if (!this.state.findPerson) {
      if (this.validaCpf()) {
        this.setState({ loading: true });
        // Chamada a API
        const responseLicenca = await APIAuth.getLicenca(this.state.cpf);

        if (responseLicenca === 200) {

          const empresas = await APIEmpresas.getEmpresas() || [];

          if (empresas.length > 0) {
            let arrEmpresas = [];
            empresas.forEach(o => {
              arrEmpresas.push({
                key: o.CODIGOEMPRESA,
                text: o.NOMEFANTASIA,
                value: o.NOMEFANTASIA,
                url: o.URL_API,
                codigo: o.CODIGOEMPRESA,
                cnpj: o.CNPJ
              })
            });

            if (arrEmpresas.length === 1) {
              const objE = arrEmpresas[0];
              storage.setUrlClient(objE.url);
              this.setState({ empresaSelect: { key: objE.key, texto: objE.text, url: objE.url, codigo: objE.codigo, value: objE.value, cnpj: objE.cnpj }, empresaI: false });
            }

            this.setState({ empresas: arrEmpresas, loading: false, findPerson: true });
          } else {
            this.showMessage("Nenhuma empresa encontrada para esse usuário", true);
          }

        } else if (responseLicenca === 404) {

          this.setState({ loading: false });
          this.showMessage("Usuário não encontrado. Por favor, entre em contato com sua empresa", true);

        } else if (responseLicenca === 502) {

          this.showMessage('Ops... algo deu errado. Vamos tentar novamente?', true);
        }


      } else {
        this.setState({ CPFI: true });
        this.showMessage("CPF Inválido", true);
      }

    } else {

      if (this.isCamposValidos()) this.validaLogin();

    }

  }

  resetForm = () => {
    this.setState({
      step: 1,
      displayEmp: 'none',
      findPerson: false,
      pass: "",
      cpf: ""
    })
  }


  gotoChangePass = () => {
    this.setState({ showResetPass: true });
  }

  closeChangePass = () => {
    this.setState({ showResetPass: false });
  }



  handleShowPassword = (event) => {
    const isBoxChecked = event.target.checked;
    this.setState({ isPasswordVisible: isBoxChecked})
  }


  render() {
    const { findPerson, empresas, empresaSelect, cpf, showResetPass } = this.state;
    return (
      <>
        {showResetPass && <ModalResetPassword
          empresa={empresaSelect.key}
          url={empresaSelect.url}
          cpf={cpf}
          close={this.closeChangePass}
        />}
        <Container>
          <Loading show={this.state.loading} />
          <SvgContainer ref={this.ref}>
            <h1>Bem-vindo ao <span>Portal do Colaborador</span></h1>
            <img
              src={SVG}
              style={{ width: this.state.svgW }}
            />
          </SvgContainer>
          <LoginContainer>
            <h2>Acesse sua conta</h2>

            <SilverInput disabled={findPerson} placeholder={"Insira seu CPF"} onChange={this.handleUser} value={this.state.cpf} />

            {findPerson && <select onChange={this.handleEmpresas}>

              {empresas.length > 1 && <option selected value={0}>Escolha sua empresa</option>}

              {empresas.length === 1 && <option selected value={empresas[0].key}>{empresas[0].text}</option>}

              {
                empresas.length > 1 && empresas.map((i, index) => {
                  return <option key={index} value={i.key}>{i.text}</option>
                })
              }
            </select>}

            {findPerson &&
            <PasswordContainer>
              <SilverInput placeholder={"Digite sua senha"} type={this.state.isPasswordVisible ? "text":"password"} value={this.state.pass} onChange={this.handlePass} />
              <Checkbox label={'Mostrar senha'} gapVertical={10} onCheck={this.handleShowPassword}/>
            </PasswordContainer> 
            }

            <ButtonAcess onClick={this.acessaEmpresas}>
              {!findPerson ? "Validar CPF" : "Acessar"}
            </ButtonAcess>

            {findPerson && <BackButton onClick={this.resetForm}>
              Voltar
            </BackButton>}

            {findPerson && <span onClick={this.gotoChangePass}>Esqueci minha senha / primeiro acesso</span>}
          </LoginContainer>
        </Container>
      </>
    )
  }
}

export default withRouter(Login);
