import styled, { keyframes } from "styled-components"
import { MdClose } from "react-icons/md"

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: rgba(0,0,0,.7);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
`

const ModalAnimation = keyframes`
  from{
    transform: translateY(-200px)
  }to{
    transform: translateY(0px);
  }
`

export const ButtonClose = styled(MdClose).attrs({
  size: 30,
  color: "#fff"
})`
  padding: 5px 0; 
  cursor: pointer;
`

export const ContainerQuestions = styled.div`
  max-width: 450px;
  min-width: 300px;  
  background: #fff;
  animation: ${ModalAnimation} .4s;  

  .infoWrongDataChandPass{
    text-align: center;
    font-size: 12px;
    font-family: ${props => props.theme.font} !important;
    color: ${props => props.theme.colors.red};
    margin-bottom: 10px;
  }
`

export const Form = styled.form`
  display: flex;
  flex: 1;
  padding: 5px 10px;
  flex-direction: column;
  >input{
    margin-top: 15px;
  }
  >span{
    font-size: 12px;
    font-family: ${props => props.theme.font} !important;
    color: ${props => props.theme.colors.red};
  }
  >input[type="submit"]{
    border: none;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    align-self: center;
    background: ${props => props.theme.colors.primary};
    color: #fff;
    border-radius: 20px;
    font-family: ${props => props.theme.font} !important;
    font-weight:500;
  }
`


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.primary};
  padding: 5px 0px;

  >h2{
    font-family: ${props => props.theme.font} !important;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0 !important;
    color: #fdfdfd;
    padding-left: 10px;
  }
`

export const NextButtom = styled.a`
  transition: all .7s;
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-family: ${props => props.theme.font} !important;
  cursor: pointer !important;
  font-weight: bold;
  &:hover{
    text-decoration: none;
  }
`


export const BackButtom = styled.a`
  transition: all .7s;
  text-decoration: none;
  color: rgba(0,0,0,.7);
  font-size: 16px;
  font-family: ${props => props.theme.font} !important;
  cursor: pointer !important;
  &:hover{
    text-decoration: none;
  }
`


export const Bottom = styled.div`
  width: 100%;
  padding: 5px 0px 15px;
  display: flex;
  justify-content: space-around;
  align-items: center; 
  &:hover{
    text-decoration: none;
  } 
`

