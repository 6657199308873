import React, { Component } from 'react';
import {
  Container,
  LogoContainer,
  MenuButton
} from './style';


export default class Header extends Component {

  state = { classActive: 'is-active' };

  clickedMenu = () => {
    if (this.state.classActive === "") this.setState({ classActive: "is-active" })
    else this.setState({ classActive: "" })
    this.props.openHeader();
  }


  render() {
    const { logo, active, visible, isMenuOpen } = this.props;
    if (visible) {


      return (
        <div>
          <Container>
            <h1>Portal do Colaborador</h1>


            {logo && <LogoContainer isMenuOpen={isMenuOpen}>
              <img src={logo} alt="logo empresa" />
            </LogoContainer>}


            <MenuButton type="button" className={`hamburger--spin ${active ? 'is-active' : ''}`} onClick={this.clickedMenu}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </MenuButton>

          </Container>
        </div>
      );
    } else {
      return null
    }
  }
}
