import styled from "styled-components";
import { DefaultCard } from "../../global/style";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  > p {
    align-self: center;
  }
`

export const ListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 10px;
  justify-content: center;

  @media (min-width: 320px) and (max-width: 990px) {
    justify-content: center;
  }
`

export const CardContainer = styled(DefaultCard)`
  width: 350px;
  height: 135px;
  border-top: 2px solid ${props => props.rejected ? '#DC3545' : '#2991D6'};
  padding: 10px;
  transition: background .5s;
  :hover{
    background: rgba(0,0,0,.1);
    cursor: pointer;
  }

`

export const CardHeader = styled.div`
 
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  height: 30px;

  h1 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
`


export const CardFooter = styled.div`
  display: flex;
  justify-content: center;

  p{
    margin:0;
    color: var(--vermelho)
  }
`