import styled from "styled-components";
import { Modal } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  margin : 0 15px 0;
`

export const ModalBox = styled.div`
  width: 420px;
  padding: 10px;
  background: #fff;
  border : none !important;
  border-radius: 5px;
`

export const Header = styled.div`
   h2{
    font-size: 20px;
    text-align: center;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 15px 5px;

  label {
    margin-top: 12px;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;   
  justify-content: flex-end;
  
  button:nth-child(1){
    margin-right: 5px;
  }
`

export const OptionButton = styled(Button)`
  padding: 10px !important;
  margin-top: 10px !important;
  background: var(--azul) !important;
  color: #fff !important;
  font-size: 12px !important;

  ${props => props.red && `
    background: var(--vermelho) !important;
  `}
`


export const CharCounterContainer = styled.div`
   width: 100%;
   text-align: right;
   padding-top: 3px;
`