import styled, { keyframes } from "styled-components";
import { Select } from 'semantic-ui-react'
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100vh;
  display : flex;
  z-index : 1200;
  justify-content : center;
  align-items : center;

  @media(max-width: 500px) {
    width : 100%;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    padding-top : 48px;
    overflow-y : auto;
    border-radius : 0;
    -webkit-border-radius : 0;
    justify-content : flex-start;
    align-items : flex-start;
  }




  background : rgba(0,0,0,.4);    
` 

const upFromDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px)
  }
`

export const Box = styled.div`
  display : flex;
  gap: 20px;
  width  : 420px;
  background : #fff;
  border-radius : 10px;
  opacity : 0;
  padding : 12px;
  flex-direction : column;
  animation : .5s ${upFromDown}  ease-in-out forwards;


  @media(max-width: 500px) {
    width : 100%;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    padding-top : 48px;
    overflow-y : auto;
    border-radius : 0
  }

  padding-bottom : 10px;
`

export const Close = styled.div`
  width : 20px;
  height : 20px;
  border-radius : 50%;
  position : absolute;
  right: 16px;
  top : -5px;
  z-index : 9999;
`

export const Input = styled.input`
  ${({ width }) => width && width + 'px'};
  border : none;
  border-bottom : 1px solid ${({ error }) => error ? 'red': '#9199A8'};
  padding : 6px 0;
  &:focus{
    border-bottom : 1px solid #2991D6;
    transition : all 1s
  }

  ${({ space }) => space ? `margin-right : 8px` : ''}
`

export const Label = styled.div`
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px;
  color : var(--azul);
  margin-bottom:  6px !important;
  padding: 0 !important;
  font-weight: bold;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CustomSelect = styled(Select)`
  width : ${({ width }) => width ? width + 'px !important' : '100% !important'};
  border-bottom : 1px solid #9199A8 !important;
  padding : 0 !important;
  ${({ space }) => space ? 'margin-right : 10px !important' : ''}
  width : 100% !important;
  border : none !important;
  -webkit-border : none !important;
  border-bottom : 1px solid #9199A8 !important;
  -webkit-border-bottom : 1px solid #9199A8 !important;
  -webkit-border-radius : 0 !important;
  border-radius: 0 !important;
  line-height: 30px !important;
  padding: 0 !important;
  font-size: 12px !important;
  color : #000 !important;  
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;  
  
`

export const Picture = styled.div`
  
  width : 50px;
  height : 50px;
  border-radius : 50%;
  
  background : ${({ color }) => color ? color : '#cccc'};
  display : flex;
  justify-content : center;
  align-items : center;
`

export const AttachFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`

export const ButtonConfirm = styled(Button)`
  background :  #2991D6 !important;
  display : flex !important;
  justify-content : center !important;
  align-items : center!important;
  animation : .5s ${upFromDown}  ease-in-out forwards;
  font-size : 14px !important;
  color : #fff !important;
  
`

export const ErrorMessage = styled.span`
  color: #DC3545;
  font-size: 12px;
`

export const PictureLabel = styled.span`
  font-size: 12px;
  width: 100px;
  text-align: center;
  ${({ error }) => error && 'color: #DC3545;'}
`