import React from 'react';

import { Container, ContainerMenu, ContainerFrame, Item } from './styles';

function HiperLinks() {
  return (
    <Container>
      <ContainerMenu>
        <Item>
          <span>Google</span>
        </Item>

        <Item>
          <span>Uol</span>
        </Item>
      </ContainerMenu>

      <ContainerFrame>
        {/* <span>Nenhuma opção selecionada ...</span> */}
        <iframe title="redmine" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=972108b7-1e73-44e0-9b6b-bc4e6517228e&autoAuth=true&ctid=c07c0756-40b3-4a08-abb2-8ca122f5c558" frameborder="0" allowFullScreen="true"></iframe>

        {/* <iframe referrerPolicy='no-referrer' title="Report Section" width="100%" height="100%" src="https://www.itau.com.br/" frameborder="0" allowFullScreen="true"></iframe> */}
      </ContainerFrame>
    </Container>

  )
}

export default HiperLinks;