import React from 'react';
import { News, NewsHeader, NewsImageContainer,NewsPreviewContainer } from './style';


export default function NewsCard({ news, open }){
  return(
    <News>
      <NewsHeader smallerTitle={news.descricao.length > 30}>
        <h4>{news.descricao}</h4>
        <NewsPreviewContainer>
          <p>{news.txtComplementar}</p>
        </NewsPreviewContainer>
        <a onClick={open}>Saiba mais</a>
      </NewsHeader>

      <NewsImageContainer>
        <img src={news.imagem}/>
      </NewsImageContainer>

    </News>
  )
}