import styled from "styled-components";
import { DefaultCard } from "../../global/style";

export const FooterCard = styled.div`
  position : fixed;
  margin : 0;
  left : 0;
  width : 100%;
  height :  30px;
  background : #000;
  bottom : 0;
`

export const CardContainer = styled(DefaultCard)`
  width: 359px !important;
  display: flex;
  
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px 0px 10px;
  margin-right: 10px;

  &:nth-child(even){
    border-top: 4px solid var(--azul);
  }

  &:nth-child(odd){
    border-top: 4px solid #32b0ff;
  }

  @media (min-width: 320px) and (max-width: 990px) {
    margin-right: 0;
  } 
`

export const Title = styled.h5`
  text-align: center;
  padding: 2px 0 0px;
  font-size: 16px;
  letter-spacing: .45px;
  
`

export const DataContainer = styled.div`
  padding: 0 10px;
`

export const Footer = styled.div`
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
  text-align: center;

`


export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 4px;
 
`

export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Label = styled.small`
  color: #A9AEB8;
`

export const Value = styled.h4`
  margin: 4px 0;
  text-transform: uppercase;
  font-size: 14px;
`

