import styled from "styled-components";

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';


export const Title = styled(DialogTitle)`
  h2 {
    font-size: 24px !important;
  }
`

export const TextContent = styled(DialogContentText)`
  font-size: 18px !important;
`

export const ButtonsContainer = styled(DialogActions)`
  button{
    font-size: 14px;
    font-weight: bold;
    color : var(--azul);
  }
`