import styled from "styled-components";
import { Icon } from 'semantic-ui-react'

export const CardContainer = styled.div`
  width: 100%;
  height: 100px;    
  border-radius: 5px; 
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14); 

  ${props => props.animate && `
    box-shadow: 0 4px 38px 0px rgba(35,51,64,.35)!important;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);  
    transition: all .4s;` 
  }

  @media (min-width: 280px) and (max-width: 1024px) {
    height: 77px;
    width: 100%;    
  }

` 

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px 10px 0;

  p {
    color : #fff;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;

    @media (min-width: 280px) and (max-width: 1024px) {
      font-size : 24px;
    }
  }
`
export const StatusText = styled.p`
  color : #fff;
  font-size: 18px !important;    
  text-align: center;    
`

export const StatusIcon = styled(Icon).attrs((props) =>({
  name: props.name
}))`
  color : #fff;
  font-size: 2.38em !important;

  @media (min-width: 280px) and (max-width: 1024px) {
    font-size: 1.35em !important;
  }
`

