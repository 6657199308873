import styled from "styled-components";
import { DefaultCard } from "../../global/style";

export const Container = styled.div`
  //border: 1px dotted red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-items: center;
  width: 1050px;

  @media (max-width: 1560px) {
    width: 1000px;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    width: 750px;
    grid-gap: 10px;

  }

  @media (max-width: 775px) {
    width: 100%;
  }

`

export const Card = styled(DefaultCard)`
  width: 100%;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    height: fit-content;
  }

 
`

export const NewsHeader = styled.div`
  padding: 15px 15px 5px;
  display: flex;
  flex-direction: column;
  
  h1 {
    font-size: 22px;
    font-weight: 500;
  }

  h2 {
    font-size: 16px;
    color: #999;
    margin: 0;
  }

  

  @media (max-width: 1024px) {
    height: fit-content;
  }
`

export const NewsPreviewContainer = styled.div`
  margin-bottom: 10px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  
  p {
    white-space: normal;
    margin: 10px 0;
    text-align: justify;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  a {
    align-self: flex-end;

    :hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  @media (max-width: 1024px) {
    height: fit-content;
  }
  
`

export const ImageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 1024px) {
    height: fit-content;
  }
`

export const SpinnerContainer = styled.div`
  margin-top: 30px;
`