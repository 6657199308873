import styled from "styled-components";
import { Button } from 'semantic-ui-react'

export const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top : 0;
  left: 0;
  z-index: 20000;
  background: rgba(0,0,0,.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;  
`

export const ModalBox = styled.div`
  width: 500px;
  height: 300px;
  background: #fff;
  border-radius: 5px;
  animation-name: message;
  animation-duration: .2s;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-delay: .2s;    
  opacity : 0;
`

export const Header = styled.div`
  width: 100%;
  padding: 11px;

  h2 {
    font-size: 16px !important;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  &:after{
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: #eee;
    margin-top: 8px;
  }
`

export const Body = styled.div`
  width: 100%;
  height: 197px;    
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 22px;
  text-align: center !important;
`

export const Footer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 55px;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  border-top: 1px solid #eee;    
`

export const OkButton = styled(Button)`
  font-size: 14px !important;
  background: var(--azul) !important;
  color: #fff !important;      
`