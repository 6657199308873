import styled from "styled-components";


export const InputBox = styled.input`
  ${({ gapVertical }) => gapVertical && `margin: ${gapVertical}px 0;`}
  margin-right: 10px;
`

export const Container = styled.div`
 ${({ gapVertical }) => gapVertical && `margin: ${gapVertical}px 0;`}
  display: flex;
  align-items: center;
  >label{
    font-size: 1.4rem;
    margin: 0 0 0 5px;
  }
  ${({ margin }) => margin && `margin: ${margin}`}
`