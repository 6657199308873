import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 25px;
  width: 100%;
  //border: 1px dotted green;
  align-self: center;
  display: flex;
  flex-direction: column;
  font-size: 32px;

  h1 {
    font-size: 1em;
    font-weight: 400;
  }

  h2 {
    font-size: .75em;
    color: #999;
    margin: 0;
  }

  p {
    margin-top: 20px;
    font-size: 18px;
    text-align: justify;
  }

  button {
    align-self: flex-end;
  }

  @media (max-width: 700px) { 
    padding: 15px;
    font-size: 24px;
    button {
      padding: 0;
    }
  }
`

export const ImageContainer = styled.div`
  margin: 25px 0;
 // border: 1px dotted blue;
  display: flex;
  justify-content: center;
 

  @media (max-width: 700px) {
    width: 100%;
    margin: 10px 0;
    img {
      width: 100%;
    }
  }
`