import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import APINews from '../../API/APINews';
import Loading from '../../components/loading/Loading';
import Post from '../../components/post';
import Spinner from '../../components/spinner/Spinner';
import { Card, Container, Grid, ImageContainer, NewsHeader, NewsPreviewContainer, SpinnerContainer } from './style';



export default function Noticias(){
  const [newsList, setNewsList] = useState([]);
  const [nextPageToLoad, setNextPageToLoad] = useState(1);
  const [allNewsHasBeenLoaded, setAllNewsHasBeenLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPost, setOpenPost] = useState(null);
  const location = useLocation();
  const ref = useRef();
  
  useEffect(() => {
    if(location.state) setNewsList(location.state.newsList);
    else loadNews();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, []);

  
  useEffect(() => {
    if(!allNewsHasBeenLoaded) loadNews();
  }, [nextPageToLoad])


  async function loadNews(){
    try{
      setIsLoading(true);
      const result = await APINews.getNoticias(nextPageToLoad);

      if(result.length === 0) {
        setAllNewsHasBeenLoaded(true);
      }
      
      if(result.err){
        return;
      }

      setNewsList(newsList.concat(result));
    }catch(e){
      console.log(e);
    }finally{
      setIsLoading(false);
    }
  }



  function openNews(post){
    setOpenPost(post);
  }

  function closePost(){
    setOpenPost(null);
  }


  function loadNextPage() {
    setNextPageToLoad(state => state+1)
  }

  function handleScroll(){
    const diff = window.innerWidth >= 1024 ? 100 : window.innerWidth >= 768 ? 80 : 55;
    const bottom = (window.innerHeight + window.scrollY) === ref.current.offsetHeight + diff;
    
    if(bottom){
      loadNextPage();
    }
  }



  return(
    <>
    {!!openPost && 
      <Post 
        data={openPost}
        closePost={closePost}
      />
    }
    <Container ref={ref}>
      {newsList.length === 0 && <Loading show={isLoading}/>}
      <Grid >
      {newsList.map((item, index) => (
        <Card>
          <NewsHeader hasLongText={item.txtComplementar.length >= 80}>
            <h1>{item.descricao}</h1>
            <h2>{item.dataHora}</h2>  
          </NewsHeader>
          {item.txtComplementar.length > 0 && 
          <NewsPreviewContainer>
            <p>{item.txtComplementar}</p>
            {item.txtComplementar.length > 355 && <a onClick={() => openNews(item)}>Leia mais</a>}
          </NewsPreviewContainer>}
          
          <ImageContainer hasLongText={item.txtComplementar.length >= 80}>
            <img src={item.imagem}/>
          </ImageContainer>
        </Card>
      ))}
      </Grid>
      {newsList.length > 0 && isLoading &&
        <SpinnerContainer>
          <Spinner color={'#000'}/>
        </SpinnerContainer> 
        
      }

    </Container>
    </>
  )
}
