import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import APINews from '../../API/APINews';
import APIDocs  from '../../API/APIDocs';
import { useHistory } from 'react-router-dom'; 

import { 
  Container, 
  Mock,
  NewsContainer, 
  NewsPageLink, 
  NewsSection, 
  Shortcut, 
  ShortcutContainer 
} from './style';

import NewsCard from './newsCard';
import APIUser from '../../API/APIUser';
import aux from '../../utils/auxiliar';
import moment from "moment";
import Skeleton from 'react-loading-skeleton';
import Loading from '../../components/loading/Loading';
import Post from '../../components/post';

export default function Home(){
  const [newsList, setNewsList] = useState([]);
  const [newsListToShow, setNewsListToShow] = useState([]);
  const [openPost, setOpenPost] = useState(null);

  const [pendingDocs, setPendingDocs] = useState(null);
  const [lastRecord, setLastRecord] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const history = useHistory();
  const ref = useRef();

  useEffect(() => {
    initPage();
  }, []);

  async function initPage(){
    setIsLoading(true);
    await loadNews();
    await loadDocs();
    await loadPonto();
    setIsLoading(false);
  }

  async function loadNews(){
    try{
      setLoadingError(false);
      setNewsList([]);

      const result = await APINews.getNoticias(1);

      if(result.err){
        return;
      }

      setNewsList(result);
      setNewsListToShow(result.slice(0,3));

    }catch{
      setLoadingError(true);
    }
  }

  async function loadDocs(){
    try{
      const result = await APIDocs.getDocs();
      if(result.err){
        setPendingDocs('--');
      }else{
        const nonSignedDocs = result.filter(doc => doc.controlaAssinatura && !doc.assinado && !doc.rejeitado);
        setPendingDocs(nonSignedDocs.length||"--");
      }
    }catch{

    }
  }


  async function loadPonto(){
    try{
      const result = await APIUser.getEspelhoPonto();

      if(result.length === 0) {
        setLastRecord('--');
        return;
      }

      const {entrada, saidaAlmoco, retornoAlmoco, saida} = result[0];

      let formattedHour;
      if(!aux.isZeroDate(saida.horario)){
        formattedHour = moment(saida.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      }else if(!aux.isZeroDate(retornoAlmoco.horario)){
        formattedHour = moment(retornoAlmoco.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      }else if(!aux.isZeroDate(saidaAlmoco.horario)){
        formattedHour = moment(saidaAlmoco.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      }else if(!aux.isZeroDate(entrada.horario)){
        formattedHour = moment(entrada.horario.slice(0, -1)).format("DD/MM/YYYY HH:mm");
      }else{
        formattedHour = '--'
      }

      setLastRecord(formattedHour);

    }catch{
      setLastRecord('--');
    }
  }

  function goToNews(){
    history.push({pathname: '/noticias', state: {newsList}})
  }

  function goToPonto(){
    history.push({pathname: '/ponto'})
  }

  function goToDocs(){
    history.push({pathname: '/documentos'})
  }

  function showPost(post){
    setOpenPost(post)
  }

  function closePost(){
    setOpenPost(false);
  }
 
  return(
    <>
    {!!openPost && 
      <Post
        data={openPost}
        closePost={closePost} 
      />
    }
    <Container ref={ref}>
      <Loading show={isLoading}/>
      <ShortcutContainer>
        <Shortcut onClick={goToPonto}>
          <h3>Ponto Diário</h3>
          <p>{lastRecord || '--'}</p>
        </Shortcut>

        <Shortcut onClick={goToDocs}>
          <h3>Documentos não vistos</h3>
          <p>{pendingDocs || '--'}</p>
        </Shortcut>
      </ShortcutContainer>

      <NewsSection>
        {!loadingError ?
        <>
        <h3>Últimas notícias</h3>

        <NewsContainer breakBefore={ref.current && window.innerWidth - ref.current.offsetWidth > 75}>
          {newsListToShow.length > 0 ? 
            newsListToShow.map(item => <NewsCard news={item} open={() => showPost(item)}/>):
            <Mock count={3} />
          }
          
        </NewsContainer>
        <NewsPageLink onClick={goToNews}>Ir para notícias</NewsPageLink>
        </> :
        <p>Algo deu errado no carregamento das notícias.<a onClick={loadNews}>Tentar novamente?</a></p>
        }

        
      </NewsSection>
    </Container>
    </>
  )
}