import styled from "styled-components";
import {DefaultCard} from '../../global/style';
import Skeleton from 'react-loading-skeleton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const ShortcutContainer = styled.div`
  display: flex;
  grid-gap: 10px;
  width: 750px;

  @media (max-width: 1152px) {
    width: 500px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Shortcut = styled(DefaultCard)`
  text-align: center;
  padding: 20px;
  font-size: 22px;
  transition: background .5s;

  h3 {
    margin: 0 0 10px;
    font-size: 1em;
    font-weight: 500;
  }

  p {
    font-size: .8em; 
  }

  :hover {
    cursor: pointer;
    background: rgba(0,0,0,.1);
  }

  @media (max-width: 1152px) {
    
    font-size: 18px;
  }

  @media (max-width: 612px) {
    font-size: 16px;
  }


`

export const NewsSection = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h3 {
    font-weight: 500;
    align-self: flex-start;
    
    @media (max-width: 1152px) {
      align-self: center;

    }

  }

  @media (max-width: 1454px) {
    width: 750px;
  }

  @media (max-width: 1152px) {
    width: 500px;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  
`

export const NewsContainer = styled.div`
  display: flex;
  grid-gap: 10px;


  @media (max-width: 1454px) {
    justify-content: center;
    flex-direction: column;
    white-space: normal;
    width: 100%;
  }

`

export const NewsPageLink = styled.a`
  align-self: center;
  margin-top: 20px;
  font-size: 22px;
  

  :hover{
    cursor: pointer;
    text-decoration: none;
  }
`

export const Mock = styled(Skeleton)`
  width: 350px !important;
  height: 345px;
  margin-right: 10px;
  
  @media (max-width: 1152px) {
    width: 100% !important;
  }
`