import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--azul);
  display: none;
  position: absolute;
  top: 45px;
  right: 0;

  
  @media(max-width: 920px){
    display: flex;
    flex-direction: column;
  }
`

export const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  color: #fff;

`

export const HeaderOptionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`

export const BackButton = styled.button`
  border: none;
  background: none;
`

export const TitleContainer = styled.div`
  padding: 20px;
  color: #fff;
  h2 {
    font-size: 18px;
    margin: 0;
  }

  h1 {
    font-size: 22px;
    margin: 0;
  }
`

export const ListContainer = styled.ul`
  list-style-type: none;
  border-radius: 40px 40px 0 0;
  padding: 0;
  background: #fff;
  flex-grow: 1;
  margin-bottom: 0;
`

export const ListItem = styled.li`
  border-bottom: 1px solid #eee;
  height: 100px;
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  gap: 15px;
  p {
    margin: 0;
    font-size: 18px;
  }

  h3 {
    margin: 0;
    justify-self: flex-end;
    font-size: 32px;
  }

  i {
    justify-self: flex-end;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    margin: 0;
    font-size: 18px;
  }

  h1 {
    margin: 0;
  }
`

