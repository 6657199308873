import styled from "styled-components"
import { Icon } from "semantic-ui-react";
import { DefaultCard } from "../../global/style";


export const ListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: 320px) and (max-width: 990px) {
    justify-content: center;
  }
`

export const CardContainer = styled(DefaultCard)`
  width: 359px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px 10px 10px 0;
  padding-bottom: 0;
  border-top:  4px solid var(--azul);

  h6 {
    font-size: 14px;
  }

  @media (min-width: 320px) and (max-width: 990px) {
    margin-right: 0;
  }
`

export const EmptyListMessage = styled.p`
  font-size: 2em;
  text-align: center !important;
  padding: 20px 0 0;
  color: rgba(139, 134, 134, 0.534);
`

export const Phone = styled.a.attrs(({ phoneNumber }) => ({
  href: phoneNumber,
}))`
  display : flex;
  width : 40px;
  height : 40px;
  border-radius : 50%;
  background : #2991D6;
  position : absolute;
  bottom: 7px;
  right : 7px;
  justify-content : center;
  align-items : center;
  text-decoration : none !important;
`


export const PhoneIcon = styled(Icon).attrs({
  name: 'phone',
  size: 'large'
})`
  color : #fff;
 
`