import styled from "styled-components";
import Button from '@material-ui/core/Button';

export const Overlay = styled.div`
  padding: 0px;
  display: flex;
  padding-top: 15px;
  justify-content: center;
  background: #3E3E3E;
  width: 100%;
  height: 100vh;
`

export const BoxContainer = styled.div`
  width: 95%;
  height: 187px;
  padding: 10px;
  margin-top: 0px;
  border-radius: 0px;
  background: rgba(0,0,0, .3);
  
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  
  p{
    padding-left: 4px;
    color : #fff;
    font-size: 16px;
  }
`

export const PadContainer = styled.div`
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;

  canvas{
    height: 100px!important;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 7px;
  display: flex;
  justify-content: flex-end;
`

export const OptionButton = styled(Button)`
  font-size:10px !important;
  background: #fff !important;
  color: #000 !important;
  margin-left : 5px !important;
`