import styled from "styled-components";
import { DefaultCard } from "../../../global/style";

export const Container = styled(DefaultCard)`
  padding: 0 10px;
  position: relative;
  border-top: 4px solid ${props => props.borderColor};
  h6 {
    margin: 15px 0 10px;
    font-weight: 600;
    font-size: 14px;
  }

  p.address {
    height: 35px;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #ccc;
  height: 50px;
  
  p {
    margin: 0;
  }
`