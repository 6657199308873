import styled from "styled-components";
import Button from '@material-ui/core/Button';
import ReactDatePicker from "react-datepicker";

export const DateSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px 0;

  h6 {
    padding-right: 10px;
  }

  
  @media(min-width: 320px) and (max-width: 568px){
    justify-content: space-around;
  }
  
  .react-datepicker__month .react-datepicker__month-text {
    margin: 13px !important;
  }

  .react-datepicker {
    font-size: 1em;
  }

  .react-datepicker__header {
    padding-top: 1.8em;
    background: var(--azul);
    color : #fff;
  }

  .react-datepicker__day-name {
    color: #fff;
  }

  .react-datepicker__month {
    margin: 1.4em 1em;
    letter-spacing: .45px;
    font-family: 'Roboto', sans-serif !important;
  }
  .react-datepicker{
    border : none;
  }
  .react-datepicker-year-header {
    font-size: 1em;
    padding: 12px;
    font-family: 'Roboto', sans-serif !important;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    line-height: 1.9em;
    margin: 0.440em;
    font-family: 'Roboto' !important;
  }
  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
    border-radius: 0.3rem;
    background-color: var(--azul);
    color: #fff;
  }
  .react-datepicker__current-month {
    font-size: 1em;
    color: #fff;
  }
  .react-datepicker__day.react-datepicker__day--001.react-datepicker__day--selected{
    background: var(--azul);
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: var(--azul);
    left: 1em;
    outline: none;
    color : #fff;
  }
  .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #fff;
  }
  .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #fff;
  }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #fff;
  }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #fff;
  }
  .react-datepicker__navigation--next {
    border-left-color: var(--azul);
    right: 1em;
  }

  .react-datepicker__time-list-item {
    width: 120px;
    border-radius: 5px;
    outline: none;
    padding: 4px;
    border: 2px solid #eee;
  }

  .react-datepicker-wrapper {
    padding: 0;
    margin: 18px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const DateButton = styled(Button)`
  font-size: 1.12em !important;
  border: 1px solid #eee !important;
  padding: 5px ${props => props.bigger ? '25px' : '10px'} 5px !important;


  @media(min-width: 320px) and (max-width: 568px){
    font-size: 12px !important;

  }
`


export const DatePicker = styled(ReactDatePicker)`
  width: 120px;
  border-radius: 5px;
  outline: none;
  padding: 4px;
  border: 2px solid #eee;
  
`