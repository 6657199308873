import styled from "styled-components";
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 4px;
`

export const Section = styled.div`
 
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  border-radius: 5px;
  margin-top: 10px;
  ${props => props.firstSection && 
 `margin-top: 80px;`}
`

export const SectionHeader = styled.div`
   position: relative;
   top: 0;
   left: 0;
   width: 100%;
   background: #eee!important;
   background: none;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   padding: 10px;
   text-align: center;

   h2 {
    font-size: 18px;
    text-align: left;
    font-family: 'Montserrat', sans-serif !important;
    color: #000;
   }

   ${props => props.hasIcon && `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
   `}
`

export const InfoContainer = styled.div`
  padding: 10px;
  
  h2 {
    font-size: 14px;
    font-weight: bold;
    color: var(--azul);
    font-family: 'Montserrat', sans-serif !important;
  }

  p {
    font-size: 14px;
    color: #000;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif !important;
  }
`

export const SectionBody = styled.div`
  padding: 10px;

  ${props => props.justify && `
    display: flex;
    justify-content: ${props.justify};
    flex-wrap: wrap;
  `}

  ${props => props.justify === 'flex-start' && `
    @media (min-width: 320px) and (max-width: 500px) {   
      justify-content: center;
    }
  `}
`

export const Value = styled.p`
  font-size: 14px;
  color: #000;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif !important;
`

export const TopBar = styled(AppBar)`
  background: var(--azul) !important;
`

export const AttachFileButton = styled(IconButton)`
  background: #fff  !important;
`