import styled from "styled-components";
import { Form } from 'semantic-ui-react';
import { DefaultCard } from "../../global/style";


export const LabelAd = styled.label`
  text-align: left;
  font-size: 14px !important;
  color: #000 !important;
  letter-spacing: .40px;
  padding : 0 !important;
  margin : 6px 0 5px !important;
`
export const InfoAd = styled.p`
  font-size : 14px;
  font-family : Roboto;
`

export const ContainerInfoAd = styled.div`
  display : flex;
  flex-direction : row;
`

export const UserInfoContainer = styled.div`
  width: 100%;  
  margin : 0 !important;
  overflow-x:  hidden;
`

export const ProfileContainer = styled(DefaultCard)`
  width: 100%;
  position: relative;
  height: fit-content;

  @media (min-width: 320px) and (max-width: 990px){
    margin-bottom: 10px;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 30px;

  @media (min-width: 320px) and (max-width: 990px){
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }

`

export const ProfileHeader = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: .45px solid #DBE2E5;
`

export const PictureContainer = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserName = styled.h6`
  font-size: 18px;
  letter-spacing: .45px;
  margin-bottom: 0px;
  text-align: center;
`

export const UserRE = styled.h6`
  font-size: 15px;
  color: #000;
`

export const ProfileBody = styled.div`
  width: 100%;
  padding: 10px 0 0;
  text-align: left;
`

export const Label = styled.small`
  color: #999;
`

export const Value = styled.h6`
  font-size: 13px;
  margin-top: 5px;
`

export const InfoRow = styled.div`
  width: 100%;
  height: auto;
  padding: 2px;
  display: flex;
  justify-content: space-between;
`

export const AdOptionContainer = styled.div`
  flex-direction: column !important;
`

export const OtherInfoContainer = styled.div`
  min-height: 500px;
  background: #F3F5F9; 
`


export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`


export const UserInfoForm = styled(Form)`
  label {
    text-align: left;
    font-size: 14px !important;
    color: #000 !important;
    letter-spacing: .40px;
  }

  input {
    font-size: 12px !important;
  }
`

export const InfoGroup = styled.div`
  width: 100%;
  padding: 50px 20px 20px !important;
  margin: 0px 0 20px !important;
  position: relative;
  margin-bottom: 10px !important;
  background: #fff;

  label{
    padding : 8px 2px 8px;  
    font-weight: 400 !important;
  }

  h6 {
    position: absolute;
    top: 0px;
    left: 14px;
    font-size: 15px;
    border-radius: 5px;
    letter-spacing: .45px;
    padding: 5px;
    color: var(--azul);
    font-weight: bold;
    text-transform: uppercase;
  }

 ${props => props.rowOriented && 
  `display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap:  wrap;
  `}

  @media (min-width: 320px) and (max-width: 990px) {
    padding: 50px 20px 10px !important;
    ${props => props.rowOriented && 
    `justify-content: center;
    flex-wrap: wrap;`
    }
  }
`

export const CornerOption = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 10px;
  right: 14px;
`

export const StyledInput = styled(Form.Input)`
  > div input {
   color: ${props => props.hasPassed ? 'red !important': '#000'};
  }
`