import styled from "styled-components";
import { Tab, Form } from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import { Modal } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;  
`

export const StatusFilterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  width: inherit;
  height: 131px;
  padding-top: 18px;
  margin: 0;
  ${props => props.isMobile && `    
    margin-top: 0px !important;       
  `}
  @media (min-width: 320px) and (max-width: 1026px) {
    grid-template-columns: 1fr 1fr;
    height: fit-content;
  }
`

export const ListContainer = styled.div`
  width: 100%;
  padding: 10px 10px 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);

  ${props => props.isMobile && `    
    margin-top: 90px !important;       
  `}

  @media (min-width: 320px) and (max-width: 1026px) {
    background: none;
    box-shadow: none;
    padding: 0;
    will-change: transform;
  }


`

export const ListHeader = styled.div`
  width: 100%;    
  display: flex;
  padding : 0px 0 10px; 
  justify-content: space-between;

`

export const ListTab = styled(Tab)`
  .menu{
    display: none;
  }

  a {
    font-size: 16px!important;
    text-align: center!important;
    background: #eaeaea!important;
    color: #000!important;
    margin-right: 0!important;
  }

  .active.page-item a {
    background: #fff!important;
    color: #000!important;
    border: none;
  }
`

export const ModalOverlay = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`

export const ModalBox = styled.div`
  width: 720px;
  padding: 10px;
  background: #fff;
  border: none !important;
  border-radius: 5px;
`

export const ModalHeader = styled.div`
  padding: 5px;
  border-bottom: 1px solid #dbdbdb;
  
  h2{
    font-size: 18px;
  }  
`

export const ModalBody = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 5px;
`

export const ModalFooter = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
`

export const FileButton = styled(Button)`
  text-transform: none !important;
  font-size: 14px !important;
  padding-left: 0 !important;
  margin: 10px 0 10px !important;
`

export const InputContainer = styled(Form.Group)`
  padding: 5px;
  font-size: 14px;

  span {
    font-weight: bold;
  }

  ${props => props.fileInput && 
  `padding: 0px 10px 0 !important`}

  label{
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
  }
`


export const DescriptionInput = styled(Form.TextArea)`
  width : 100% !important;    
  resize: none;
  margin: 0px !important;
`

export const ModalButton = styled(Button)`
  color : #fff !important;
  font-size: 18px !important;
  margin-left: 20px !important;
  ${props => props.bgColor && `background: ${props.bgColor} !important;`}
`

export const FloatingButtonContainer = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: fixed;
  z-index: 10000;    
  bottom: 12px !important;
  right: 12px;
`