import ReactDOM from 'react-dom';
import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import DefaultPage from './pages/deafultPage/DefaultPage';
import Login from "./pages/Login";
import Page404 from "./pages/404"
import {
    ThemeProvider
} from "styled-components"

import PrincipalTheme from "./theme/principal"
import { ShowMessageProvider } from "./contexts/showmessage"

/* os tipos de paramtros são : 
 * at = ACCESS_TOKEN
 * tpl =  TOKEN_PLUS_LICENCA -> é o merge dos dois tokens, pois é utilizado nas requisições
 * lc = TOKEN_LICENCA  
 * urlcli = url do cliente
 * objempresa = Um objeto json contendo as informacoes da empresa
 */


ReactDOM.render(
    <HashRouter>
        <ShowMessageProvider>
            <ThemeProvider theme={PrincipalTheme}>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path="/home/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/sac/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/ponto/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/dadosuser/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/ferias/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/beneficios/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/cursosdisponiveis/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/historicocursos/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/noticias/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/documentos/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/monitoramentofuncionario/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="/hiperlinks/:at?/:tpl?/:lc?/:urlcli?/:objempresa?" component={DefaultPage} />
                    <Route path="*" component={Page404} />
                </Switch>
            </ThemeProvider>
        </ShowMessageProvider>

    </HashRouter>

    , document.querySelector('#root')

);
