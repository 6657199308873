import styled from "styled-components";
import { Icon } from 'semantic-ui-react'

export const Container = styled.div`
  width: 100%;
  display: flex;
  color: #000;
  justify-content: center;
  background: none;
  padding: 18px 10px 18px;
  cursor: pointer;
  font-size: 12px;
  transition: all .2s;

  p {
    margin-left: 10px;
    font-weight: 300;
    font-size: 12px;
  }

  :hover {
    color: var(--azul) !important;
  }

  ${props => props.selected && `
    color: var(--azul) !important;
  `}

  ${props => props.hideBackGround && `
    background: none !important;
    box-shadow: none;
   `}
`

export const RouteIcon = styled(Icon)`
  font-size: 18px !important;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 200px;

  p {
    font-weight: 500;
  }

`

export const SubMenu = styled.div`
  width: 100%;
  height: 0px;
  overflow: hidden;
  text-align: left;
  transition: all .1s;
  padding-left: 100px;

  ${props => props.isOpen && `
    height: auto !important;
    transition: all .1s;
    padding-top: 1px;
    padding-bottom: 1px;
 `}

`

export const SubMenuItem = styled.p`
  padding: 5px 15px 5px;
  border-radius: none !important;
  border : none !important;
  cursor: pointer;

  ${props => props.selected && `
    color: var(--azul) !important;
  `}
`